
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTdatatableheaders.vue";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "TableTab",
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    selectedType: { type: Array, required: true },
    pagetitle: { type: String, required: true },
    downloadUrl: { type: String, required: true },
    downloadprops: { type: String, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
  },
  computed: {
    getPlaceholderText() {
      if (this.colnames && this.colnames.length > 0) {
        return "";
      } else {
        return "Please select";
      }
    },
  },
  components: { Datatable },
  setup(props) {
    const colnames = ref(props.selectedType);
    const tableValues = ref(props.tableData);
    const OriginalTableHeaders = ref(props.tableHeader);
    const search = ref<string>("");
    const initHistoric = ref(props.tableData);
    const downloadURL: any = ref(props.downloadUrl);
    const downloadProperties: any = ref(props.downloadprops);

    onMounted(() => {
      initHistoric.value = JSON.parse(JSON.stringify(props.tableData));
    });

    const searchItems = () => {
      tableValues.value.splice(
        0,
        tableValues.value.length,
        ...initHistoric.value
      );

      if (search.value !== "") {
        const results = JSON.parse(JSON.stringify(props.tableData));
        results.splice(0);

        for (let j = 0; j < tableValues.value.length; j++) {
          if (searchingFunc(tableValues.value[j], search.value)) {
            results.push(tableValues.value[j]);
          }
        }
        tableValues.value.splice(0, tableValues.value.length, ...results);
      }
    };

    function downloadItem() {
      ApiService.post(downloadURL.value, downloadProperties.value)
        .then((resp) => {
          let domainName = store.getters.DomainName.toLowerCase();
          let url =
            ApiService.vueInstance.axios.defaults.baseURL +
            "api/Downloadfile/dwnFile/" +
            domainName +
            "/xlsx/out/" +
            resp.data +
            "/" +
            0;
          window.open(url);
        })
        .catch((a) => {
          //console.log(a);
        });
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!(typeof obj[key] === "object")) {
          if (
            String(obj[key]).toLowerCase().trim().includes(value.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      search,
      searchingFunc,
      searchItems,
      colnames,
      tableValues,
      OriginalTableHeaders,
      downloadItem,
    };
  },
});
